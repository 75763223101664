<template>
  <div class="container mx-auto text-center">
    <p class="font-heading text-xl pb-4" v-if="userName">Willkommen, {{ userName }}!</p>
    <img src="@/assets/icon.svg" alt="Login Image" class="w-8 h-8 mb-4 mx-auto block" />

    <h1 class="text-3xl font-bold mb-6">Aktueller KITA-Speiseplan</h1>

    <div v-if="Object.keys(meals).length">
      <div v-for="(mealsArray, day) in meals" :key="day" class="mb-8">
        <h2 class="text-2xl font-semibold text-gray-700">{{ day }} </h2>
        <ul class="mt-4 space-y-4">
          <li v-for="meal in mealsArray" :key="meal.id" class="p-4 border rounded-lg bg-white shadow-sm">
            <strong class="text-xl text-primary">{{ meal.name }}</strong>
            <p class="text-gray-500 pb-4">{{ meal.description }}</p>
            <strong class="text-xl text-primary">{{ meal.dessert }}</strong>

            <!-- Eingabefeld für die Personenzahl je Gruppe -->
            <div v-for="group in groups" :key="group.id" class="mt-4">
              <label :for="'persons-' + group.id + '-' + meal.id" class="block text-gray-700">
                Anzahl Personen für {{ group.name }}:
              </label>
              <input
                  v-if="meal.groupPersons && meal.groupPersons[group.id] !== undefined"
                  :id="'persons-' + group.id + '-' + meal.id"
                  type="number"
                  v-model.number="meal.groupPersons[group.id]"
                  placeholder="Anzahl Personen"
                  class="mt-2 p-2 border rounded w-full"
                  :readonly="!meal.active"
                  :class="{'bg-gray-200 cursor-not-allowed': !meal.active, 'bg-white': meal.active}"
                  required
              />
            </div>
          </li>
        </ul>

        <!-- Button zum Senden der Daten für alle Mahlzeiten an diesem Tag -->
        <button
            v-if="isDayOrderActive(mealsArray)"
            @click="submitDayOrder(day, mealsArray)"
            class="mt-4 p-2 bg-primary text-white rounded"
        >
          Bestellung für {{ day }} abschicken
        </button>
      </div>
    </div>
    <p v-else class="text-center text-gray-500">Der Speiseplan wird geladen...</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      meals: [],
      groups: [], // Liste der Gruppen
      userName: localStorage.getItem('user_name')
    };
  },
  mounted() {
    // Lade die Gruppen des Benutzers
    axios.get(process.env.VUE_APP_API_URL + '/user/groups', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
      }
    })
        .then(response => {
          this.groups = response.data;
          return axios.get(process.env.VUE_APP_API_URL + '/meals/list', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
            }
          });
        })
        .then(mealsResponse => {
          this.meals = mealsResponse.data;

          // Initialisiere die Personenanzahl für jede Gruppe in jeder Mahlzeit
          Object.keys(this.meals).forEach(day => {
            this.meals[day].forEach(meal => {
              // Wenn die Personenanzahl bereits vorhanden ist, verwenden wir diese
              meal.groupPersons = meal.groupPersons || {};

              this.groups.forEach(group => {
                if (meal.groupPersons[group.id] === undefined) {
                  this.$set(meal.groupPersons, group.id, 0);
                }
              });
            });
          });
        })
        .catch(error => {
          console.error("There was an error fetching the data:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push('/login');
          }
        });
  },
  methods: {
    // Prüfe, ob mindestens ein Menü des Tages aktiv ist
    isDayOrderActive(mealsArray) {
      return mealsArray.some(meal => meal.active); // Gibt true zurück, wenn mindestens ein Menü aktiv ist
    },
    submitDayOrder(day, mealsArray) {
      let successfulSubmissions = 0;
      const totalSubmissions = mealsArray.length * this.groups.length;

      mealsArray.forEach(meal => {
        this.groups.forEach(group => {
          // Wenn das Eingabefeld leer ist, setze den Wert auf 0
          const persons = meal.groupPersons[group.id] || 0;

          const data = {
            meal_id: meal.id,
            group_id: group.id,
            persons: persons,
            user_id: localStorage.getItem('user_id')
          };

          axios.post(process.env.VUE_APP_API_URL + '/meals/order', data, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
            }
          })
              .then(response => {
                console.log(response.data);
                successfulSubmissions++;
                if (successfulSubmissions === totalSubmissions) {
                  alert(`Bestellungen für ${day} erfolgreich abgeschickt!`);
                }
              })
              .catch(error => {
                console.error(`Es gab einen Fehler beim Abschicken der Bestellung für ${meal.name}, ${group.name}:`, error);
                alert(`Fehler beim Abschicken der Bestellung für ${meal.name} in der Gruppe ${group.name}.`);
              });
        });
      });
    }
  }
};
</script>

<style scoped>
/* Zusätzliche Styles, falls benötigt */
</style>