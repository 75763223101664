<template>
  <div id="app" class="bg-gray-100">
    <NavigationBar v-if="showNavbar" />
    <div class="container mx-auto mt-8">
      <router-view />
    </div>
    <FooterBar />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import NavigationBar from './components/NavigationBar.vue';
import FooterBar from './components/FooterBar.vue';
export default {
  components: {
    NavigationBar,
    FooterBar
  },
  setup() {
    const route = useRoute();

    // Berechne, ob die Navbar angezeigt werden soll
    const showNavbar = computed(() => route.name !== 'Login');

    return { showNavbar };
  }
};
</script>

<style>
/* Allgemeine Styles */
</style>