<template>
  <div class="flex justify-center items-center min-h-screen bg-gray-100">
    <div class="text-center">
      <h1 class="text-4xl font-bold text-gray-800 mb-4">404 - Seite nicht gefunden</h1>
      <p class="text-gray-600 mb-8">Die Seite, die du suchst, existiert nicht.</p>
      <router-link to="/meals" class="text-blue-500 hover:underline">
        Zurück zur Startseite
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
/* Eigene Stile für die 404-Seite */
</style>