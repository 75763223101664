import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import './assets/main.css'
const app = createApp(App);

app.use(router); // Füge den Router der Vue-App hinzu

router.beforeEach((to, from, next) => {
    if (to.name === 'Login') {
        document.body.style.backgroundColor = '#f3f4f6';
    } else {
        document.body.style.backgroundColor = '';
    }
    next();
});

app.mount('#app');



