<template>
  <footer class="bg-secondary text-white p-4 mt-8">
    <div class="container mx-auto text-center">
      <p class="mb-2">
        &copy; {{ currentYear }} NAUMANNs KITA-App
      </p>
      <p>
        <a href="https://www.metzgerei-naumann.de/impressum/" target="_blank" rel="noopener noreferrer" class="text-white hover:text-gray-300 mx-2">
          Impressum
        </a>
        |
        <a href="https://www.metzgerei-naumann.de/datenschutz/" target="_blank" rel="noopener noreferrer" class="text-white hover:text-gray-300 mx-2">
          Datenschutz
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
/* Zusätzliche spezifische Stile für den Footer */
</style>