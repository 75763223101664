<template>
  <nav class="bg-secondary text-white p-4 sticky top-0 z-50">
    <div class="container mx-auto flex justify-between items-center">
      <div class="text-lg font-bold">
        <router-link to="/meals" class="hover:text-gray-300">NAUMANNs KITA-App</router-link>
      </div>
      <ul class="flex space-x-4">
        <li>
          <button @click="logout" class="hover:text-gray-300 focus:outline-none">
            Abmelden
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: 'NavigationBar',
  methods: {
    logout() {
      // Optional: Sende eine Logout-Anfrage an das Backend
      axios.post(process.env.VUE_APP_API_URL + '/logout', {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
        }
      })
          .then(response => {
            console.log("Logout response:", response.data);
          })
          .catch(error => {
            console.error("There was an error logging out:", error);
          });

      // Entferne alle Benutzerdaten aus dem localStorage
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_name');

      // Leite den Benutzer zur Login-Seite weiter
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
/* Zusätzliche spezifische Stile für die Navbar */
</style>