import { createRouter, createWebHistory } from 'vue-router';
import MealsList from '../components/MealsList.vue';
import LoginForm from '../components/LoginForm.vue';
import NotFound from '../components/NotFound.vue'; // Importiere die 404-Komponente

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginForm,
    },
    {
        path: '/meals',
        name: 'Meals',
        component: MealsList,
        meta: { requiresAuth: true },
    },
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/:pathMatch(.*)*', // Fängt alle nicht definierten Routen ab
        name: 'NotFound',
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('auth_token');
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router;