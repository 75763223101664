<template>
  <div class="flex justify-center items-center min-h-screen">
    <div class="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
      <div class="flex justify-center mb-6">
        <img src="@/assets/logo.png" alt="Login Image" class="w-64 h-32 object-contain" />
      </div>
      <h2 class="text-2xl font-semibold text-center mb-6">Anmeldung</h2>
      <form @submit.prevent="login">
        <div class="mb-4">
          <label for="email" class="block text-gray-700">E-Mail</label>
          <input
              type="email"
              v-model="email"
              required
              placeholder="Gib deine E-Mail ein"
              class="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div class="mb-6">
          <label for="password" class="block text-gray-700">Passwort</label>
          <input
              type="password"
              v-model="password"
              required
              placeholder="Gib dein Passwort ein"
              class="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
            type="submit"
            :disabled="loading"
            class="w-full px-4 py-2 text-white bg-primary rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
        >
          {{ loading ? 'Wird geladen...' : 'Anmelden' }}
        </button>

        <p v-if="error" class="mt-4 text-red-600 text-center">{{ error }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: null,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/login', {
          email: this.email,
          password: this.password,
        });

        localStorage.setItem('auth_token', response.data.token);
        localStorage.setItem('user_id', response.data.user_id);
        localStorage.setItem('user_name', response.data.name);

        this.$router.push('/meals');
      } catch (error) {
        this.error = 'Login fehlgeschlagen. Bitte überprüfe deine Zugangsdaten.';
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Zusätzliche spezifische Stile können hier hinzugefügt werden */
</style>